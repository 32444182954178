@charset "utf-8";



// Our variables
$base-font-family: 'Open Sans', Helvetica, Arial, sans-serif;
$base-font-size:   .95em;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #0077CC;
// $gameday-sky: #109dc0;
$clemson-purple:      #522d80;
$clemson-orange:      #f66733;


$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);


// Width of the content area
$content-width:    950px;

$on-palm:          600px;
$on-laptop:        950px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "header",
	"mobile-header",
        "base",
        "layout",
        "syntax-highlighting",
	"fancy-image"
;
